import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './Napology.styles';
import SectionWrapper from '../Common/SectionWrapper';
import dreamTeam from '../../assets/icons/worker-dreaming.svg';

import { theme } from '../../styles/theme';
import SectionHeaderText from '../Common/Texts/SectionHeaderText';

const Napology = () => {
  const { t } = useTranslation();

  return (
    <SectionWrapper backgroundColor={theme.color.backgroundSecondary}>
      <SectionHeaderText>{t('about_napology_title')}</SectionHeaderText>
      <S.Wrapper>
        <S.Paragraph>{t('about_napology_paragraph_1')}</S.Paragraph>
        <S.ImageWrapper>
          <S.LightbulbImg src={dreamTeam} alt="worker dreaming" />
        </S.ImageWrapper>
        <S.Paragraph>{t('about_napology_paragraph_2')}</S.Paragraph>
      </S.Wrapper>
    </SectionWrapper>
  );
};

export default Napology;
